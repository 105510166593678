export function header() {

  const header = document.querySelector('.j-header');
  const header_btn = document.querySelector('.j-header_btn');

  function hamburger() {
    header.classList.toggle('active');
  }

  header_btn.addEventListener('click', ()=>{
    hamburger();
  });

  let list = document.getElementsByClassName('j-header_link');

  for(let i = 0; i < list.length; i++) {
    list[i].addEventListener('click', ()=>{
      hamburger();
    });
  }
}
